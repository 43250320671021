.music-box {
  flex-grow: 1;
  padding: 27px;
  border-radius: 15px;
  display:flex;
  flex-direction: column;
  transform-origin: bottom;
  transition: transform 0.26s ease;
}

#email-label{
  color:red !important;
}

p.MuiFormHelperText-root{
  color: red;
}

input.inputemail {
  color:red;
  border-color: green;
}

button.submitbutton {
  background-color: #482880;
  color: #b2a300;
}

button.tour-button{
  background-color: #b2a300;
  color: #482880;

}
@media only screen and (max-width: 760px) {
  .music-box{
    margin: 0;
  }
}
.MuiInput-underline:after {
  border-bottom-color: #b2a300 !important;
}
.playarea {
  margin-top: 10px;
  padding:10px;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-color: #482880;
  flex-grow: 1;
  vertical-align: middle;
  display: flex;
  flex-wrap: wrap;
}

.centered-header {
  text-align: center;
  background-color:#673ab7;
  display: flex;
  align-items: center;
  justify-content: center;
  color:#ffb74d;
}

.centered-header {
  border-radius: 10px;
}
.MuiFormControl-fullWidth{
    width:50% !important;
}
.centered-header div {
  margin: 3px;
  padding: 5px;
}
.submit-form.centered-header {
  border-radius: 20px;
  display:flex;
  justify-content: space-evenly;

}

.custom-checkbox{
  display:flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

label.checkbox{
  padding: 5px;
  width: 30%;
}

.playarea.only-element{
  display:none;
}

.shaker-container{

}
