.menuItem {
  padding: 5px;
  min-height: 0;
  background-color: grey;
  transform-origin: top;
  transition: transform 0.26s ease;
  background-color:#482880;
  color:#81c784;
  border-radius: 15px;
  margin: 5px;
}

div.label {
  display: flex;
  align-items: center;
  padding: 5px;
}

div.label span  {
  color: #81c784;
}

.MuiIcon-colorPrimary {
    color: #8561c5 !important;
}

div.offset {
  padding-left: 10px;
}

.menuItemContainer {
  border-radius: 10px;
}

@media screen and (max-width: 760px) {

  div.label {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  div.offset {
    padding-left: 10px;
  }
}

@media screen and (max-width: 600px){
  .menuItem {
    margin: 0px;
    border-radius: 0px;
  }
  div.label {
    justify-content: left;
  }
}
