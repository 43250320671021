.sidebar.open {
}
.sidebar.closed {
  width: 50px;

}

.sidebar {
  transition: width .6s;
  transition-timing-function: ease;
}

@media screen and (max-width: 760px) {
  .sidebar {
    display: flex;
    justify-content: space-evenly;
  }
  .sidebar.closed{
    width:inherit;
  }

}

@media screen and (max-width: 600px) {
  .sidebar {
    flex-shrink: 5;
  }
  .sidebar.open {
    display: flex;
    flex-direction: column;
  }

}
